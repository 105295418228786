import React from 'react';

const FourOhFour = () => (
  <div>
    <h1>
404
    </h1>
    <p>
Nothing is here.
    </p>
  </div>
);

export default FourOhFour;
